import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import SEO from '../components/atoms/SEO';
import GenericPage from '../components/layout/CommonLayout/GenericPage';
import useAnalyticsSessionData from '../hooks/useAnalyticsSessionData';

export default function AcceptableUse() {
  useAnalyticsSessionData();

  return (
    <GenericPage>
      <SEO title="Acceptable use policy" />
      <Box>
        <Box p="6% 0% 1% 10%" width="100%" alignItems="left">
          <Typography variant="h4">Acceptable use policy</Typography>
          <p>
            <em>Last updated 27 April 2020</em>
          </p>
        </Box>
        <Box p="1% 10% 3% 10%" width="100%">
          <p>
            <strong>1. ABOUT THIS POLICY</strong>
          </p>
          <p>
            This acceptable use policy sets out the terms between you and us
            under which you may access our websites buildtorent.io or any
            subdomain (our “Sites“), whether as a guest or a registered user.
            Your use of our Sites (and, where relevant, express acknowledgement
            of this policy) means that you accept, and agree to abide by, all
            the policies in this acceptable use policy, which supplement our
            website <a href="/terms">Terms of use</a>.
          </p>
          <p>
            Only persons aged over 18 may use the interactive services on our
            site. By using such services you warrant to us that you are over the
            age of 18. Our Sites are operated by B2Rent Ltd (“We” or “us“). Our
            office is located at 63/66 Hatton Garden, Fifth Floor, Suite 23,
            London EC1N 8LE.
          </p>
          <p>
            <strong>2. PROHIBITED USES</strong>
          </p>
          <p>
            You may use our Sites only for lawful purposes. You may not use our
            Sites:
          </p>
          <ul>
            <li>
              in any way that breaches any applicable local, national or
              international law or regulation;
            </li>
            <li>
              in any way that is unlawful or fraudulent, or has any unlawful or
              fraudulent purpose or effect;
            </li>
            <li>
              for the purpose of harming or attempting to harm individuals and,
              in particular, minors in any way;
            </li>
            <li>
              to send, knowingly receive, upload, download, use or re-use any
              material which does not comply with our content standards below;
            </li>
            <li>
              to break or circumvent our security measures or otherwise test the
              vulnerability of our systems or networks; and/or
            </li>
            <li>
              knowingly to transmit any data, and/or to send and/or upload any
              material that contains viruses, Trojan horses, worms, time-bombs,
              keystroke loggers, spyware, adware or any other harmful programs
              or similar computer code designed to adversely affect the
              operation of any computer software or hardware.
            </li>
          </ul>
          <p>You also agree:</p>
          <ul>
            <li>
              not to reproduce, duplicate, copy or re-sell any part of our Sites
              in contravention of the provisions of our terms of use; and/or
            </li>
            <li>
              not to access without authority, interfere with, damage or
              disrupt:
              <ul>
                <li>any part of our Sites;</li>
                <li>any equipment or network on which our Sites are stored;</li>
                <li>any software used in the provision of our Sites; or</li>
                <li>
                  any equipment or network or software owned or used by any
                  third party.
                </li>
              </ul>
            </li>
          </ul>
          <p>
            <strong>3. INTERACTIVE SERVICES</strong>
          </p>
          <p>
            We may from time to time provide interactive services on our Sites,
            including, without limitation:
          </p>
          <ul>
            <li>property reviews;</li>
            <li>frequently asked questions;</li>
            <li>chat rooms;</li>
            <li>blogs.</li>
          </ul>
          <p>
            (the &#8220;<strong>interactive services</strong>&#8220;).
          </p>
          <p>
            Where we do provide any interactive service, we will provide clear
            information to you about the kind of service offered, if it is
            moderated and what form of moderation is used, if any. If you wish
            to complain about a comment posted on our Sites or you think that a
            comment has been posted that is in breach of this acceptable use
            policy please report it to us using the “report” button on our Sites
            or contact us at{' '}
            <a href="mailto:info@buildtorent.io">info@buildtorent.io</a>, we
            will then review this comment. Please note that reporting a comment
            does not necessarily mean the comment will be removed.
          </p>
          <p>
            We are under no obligation to oversee, monitor or moderate any
            interactive service we provide on our Sites, and (except for in
            respect of French users, where we can only be held liable as set out
            in the law for trust in the digital economy dated 6 June 2004) we
            expressly exclude our liability for any loss or damage arising from
            the use of any interactive service by a user in contravention of our
            content standards, whether the service is moderated or not.
          </p>
          <p>
            Where we do moderate an interactive service, we will normally
            provide you with a means of contacting the moderator, should a
            concern or difficulty arise.
          </p>
          <p>
            <strong>4. CONTENT STANDARDS</strong>
          </p>
          <p>
            These content standards apply to any and all material which you
            contribute to our Sites (&#8220;<strong>contributions</strong>
            &#8220;) including reviews and questions submitted to us.
          </p>
          <p>
            The following standards and requirements apply to your use of and
            contributions to our Sites.
          </p>
          <p>Your contributions must:</p>
          <ul>
            <li>be accurate (where they state facts);</li>
            <li>be genuinely held (where they state opinions); and</li>
            <li>
              comply with applicable law in any country from which they are
              posted
            </li>
          </ul>
          <p>Your contributions must not:</p>
          <ul>
            <li>
              contain any material which is defamatory of any person or obscene,
              offensive, hateful or inflammatory;
            </li>
            <li>
              promote sexually explicit material, violence, discrimination based
              on race, sex, religion, nationality, disability, sexual
              orientation or age;
            </li>
            <li>
              infringe any copyright, database right or trade mark or other
              right of any other person;
            </li>
            <li>
              be likely to deceive any person, including using your
              contributions to impersonate any person, or to misrepresent your
              identity or affiliation with any person;
            </li>
            <li>
              be made in breach of any legal duty owed to a third party, such as
              a contractual duty or a duty of confidence;
            </li>
            <li>
              promote any illegal activity or advocate, promote or assist any
              unlawful act such as (by way of example only) copyright
              infringement or computer misuse;
            </li>
            <li>
              be threatening, abuse or invade another&#8217;s privacy, or cause
              annoyance, inconvenience or needless anxiety or be likely to
              harass, upset, embarrass, alarm or annoy any other person; or
            </li>
            <li>
              give the impression that they emanate from us, if this is not the
              case.
            </li>
          </ul>
          <p>
            <strong>5. SUSPENSION AND TERMINATION</strong>
          </p>
          <p>
            We will determine, at our reasonable discretion, whether there has
            been a breach of this acceptable use policy through your use of our
            Sites. When a breach of this policy has occurred, we may take such
            proportionate action as we deem appropriate.
          </p>
          <p>
            Failure to comply with this acceptable use policy constitutes a
            material breach of the terms of use upon which you are permitted to
            use our Sites, and may result in our taking all or any of the
            following actions:
          </p>
          <ul>
            <li>issuing a warning to you</li>
            <li>
              immediate, temporary or permanent withdrawal of your right to use
              our Sites;
            </li>
            <li>
              immediate, temporary or permanent removal of any posting or
              material uploaded by you to our Sites;
            </li>
            <li>
              taking legal proceedings against you for reimbursement of all
              costs on an indemnity basis (including, but not limited to,
              reasonable administrative and legal costs) resulting from the
              breach;
            </li>
            <li>taking legal action against you; and/or</li>
            <li>
              disclosing such information to law enforcement authorities as we
              reasonably feel necessary.
            </li>
          </ul>
          <p>
            Unless you are a resident of France, to the maximum extent permitted
            by applicable law, we exclude liability for actions taken in
            response to breaches of this acceptable use policy. For users in any
            territory, the responses described in this policy are not an
            exhaustive list, and we may take any other action we reasonably deem
            appropriate.
          </p>
          <p>
            <strong>6. CHANGES TO THE ACCEPTABLE USE POLICY</strong>
          </p>
          <p>
            We may revise this acceptable use policy at any time by amending
            this page. We will use appropriate means, such as relevant
            announcements on our website, to inform you on such amendments.
            However, we ask you to check this page from time to time to take
            notice of any changes we make, as you will be subject to the terms
            and conditions in force at the time that you use our Sites. If you
            do not agree with the changes, you must stop using the Sites. Some
            of the provisions contained in this acceptable use policy may also
            be superseded by provisions or notices published elsewhere on our
            Sites.
          </p>
        </Box>
      </Box>
    </GenericPage>
  );
}
